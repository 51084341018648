@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=latin-ext");
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out; }

html {
  box-sizing: border-box;
  overflow-x: hidden; }

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 300; }
  body.home .header {
    border-bottom: none; }

*, *:before, *:after {
  box-sizing: inherit; }

a {
  text-decoration: none;
  color: #5fc7cc; }

strong {
  font-weight: 700; }

h1 {
  display: inline-block; }

img.alignleft {
  float: left;
  margin: 0 20px 10px 0; }

img.alignright {
  float: right;
  margin: 0 0 10px 20px; }

/*@import '../scss/modules/_';*/
.header {
  width: 100%;
  top: 0;
  z-index: 50;
  background-color: #FFF;
  transition: all .5s ease-in-out;
  padding-top: 10px;
  border-bottom: 1px solid #5dc6cc; }
  .header__right-wrapper {
    pointer-events: none; }
  .header__right {
    pointer-events: all; }
  .header .fb {
    width: 25px;
    margin-bottom: 10px;
    display: block;
    float: right; }
    .header .fb img {
      max-width: 100%; }
    @media (min-width: 992px) {
      .header .fb {
        width: 40px;
        margin-bottom: 0; } }
  .header__left__logo img {
    max-width: 230px; }
    @media (min-width: 768px) {
      .header__left__logo img {
        max-width: 270px; } }
  .header .hamburger {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    width: 44px;
    height: 44px;
    overflow: hidden;
    z-index: 10; }
    @media (min-width: 768px) {
      .header .hamburger {
        display: none; } }
    .header .hamburger > span {
      background-color: #5dc6cc;
      width: 30px;
      height: 3px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      transition: all .3s ease-in-out; }
      .header .hamburger > span:nth-child(1) {
        top: 12px; }
      .header .hamburger > span:nth-child(2) {
        top: 20px;
        opacity: 1; }
      .header .hamburger > span:nth-child(3) {
        top: 28px; }
    .header .hamburger--active > span:nth-child(1) {
      top: 50%;
      transform: translateX(-50%) rotate(135deg); }
    .header .hamburger--active > span:nth-child(2) {
      top: 20px;
      left: -100%;
      opacity: 1; }
    .header .hamburger--active > span:nth-child(3) {
      top: 50%;
      transform: translateX(-50%) rotate(-135deg); }
  .header__right {
    clear: both; }
    .header__right .search {
      width: 80%;
      float: right; }
  .header .nav {
    position: absolute;
    float: left;
    z-index: 90;
    background-color: #fff;
    left: -100%;
    padding: 0 20px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    transition: left .3s ease-in-out; }
    .header .nav.js-nav-show {
      left: 0; }
    @media (min-width: 768px) {
      .header .nav {
        padding: 0;
        display: flex;
        position: static;
        height: auto;
        width: auto; } }
    .header .nav a {
      font-family: Roboto, sans-serif;
      display: block;
      color: #455757;
      text-decoration: none;
      font-size: 18px;
      font-weight: 300;
      text-transform: lowercase;
      padding: 10px 0;
      margin-right: 40px;
      border-bottom: 4px solid transparent; }
    .header .nav li {
      display: inline-block;
      position: static;
      display: block;
      white-space: nowrap; }
      @media (min-width: 768px) {
        .header .nav li {
          display: inline-block; } }
      .header .nav li:last-child a {
        margin-right: 0; }
    @media (min-width: 992px) {
      .header .nav > li:hover > a, .header .nav > li.current-menu-item > a, .header .nav > li.current-menu-parent > a {
        color: #5dc6cc;
        background: transparent;
        border-bottom: 4px solid #5dc6cc; }
      .header .nav > li:hover > ul, .header .nav > li.current-menu-item > ul, .header .nav > li.current-menu-parent > ul {
        pointer-events: auto;
        opacity: 1; } }
    .header .nav > li > ul {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      z-index: 90;
      background: #FFF;
      height: 50px;
      left: 15px;
      border-top: 1px solid #5dc6cc; }
      .header .nav > li > ul:before {
        content: '';
        display: block;
        position: absolute;
        width: 1000px;
        left: -1000px;
        top: -1px;
        height: 50px;
        background: #FFF; }
      .header .nav > li > ul:after {
        content: '';
        display: block;
        position: absolute;
        width: 1000px;
        right: -1000px;
        top: -1px;
        height: 50px;
        background: #FFF; }
      .header .nav > li > ul > li:hover > a, .header .nav > li > ul > li.current-menu-item > a {
        color: #5dc6cc;
        background: transparent; }
      .header .nav > li > ul > li a {
        display: block;
        font-size: 14px;
        margin-right: 10px; }

.header__right__phone {
  white-space: nowrap; }

.footer {
  padding: 40px 0;
  background-color: #455757; }
  .footer__logo {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #cca36a;
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .footer__logo {
        width: 360px; } }
    .footer__logo > img {
      margin: 0 auto;
      display: block; }
  .footer__left, .footer__center, .footer__right {
    display: block;
    width: 100%; }
    @media (min-width: 768px) {
      .footer__left, .footer__center, .footer__right {
        display: inline-block;
        vertical-align: top;
        width: 33%; } }
  .footer__left {
    color: #FFF; }
    .footer__left em {
      color: #5dc6cc; }
    @media (min-width: 1200px) {
      .footer__left {
        width: 31%; } }
    .footer__left > p:nth-child(2) {
      margin: 10px 0; }
  .footer__center {
    margin: 30px 0; }
    @media (min-width: 768px) {
      .footer__center {
        margin: 0; } }
    @media (min-width: 1200px) {
      .footer__center {
        width: 50%;
        vertical-align: bottom; } }
    .footer__center__contact {
      margin-bottom: 10px; }
      @media (min-width: 1200px) {
        .footer__center__contact {
          display: inline-block;
          vertical-align: middle;
          margin-right: 50px; } }
      .footer__center__contact__icon {
        position: relative;
        width: 45px;
        height: 45px;
        border: 1px solid #b0dbdd;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px; }
        .footer__center__contact__icon > img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .footer__center__contact .text {
        dispaly: inline-block;
        vertical-align: middle;
        color: #b0dbdd; }
        .footer__center__contact .text:hover {
          text-decoration: underline; }
  @media (min-width: 1200px) {
    .footer__right {
      width: 18%; } }
  .footer__right .text {
    margin-bottom: 10px; }
  .footer__right__list > li {
    padding: 2px 0; }
    .footer__right__list > li > a {
      font-family: "Roboto", sans-serif;
      font-size: 1.6rem;
      color: #fff;
      padding: 5px 0; }

.heading {
  font-family: "Roboto", sans-serif;
  font-size: 3.5rem;
  font-weight: 100;
  color: #5dc6cc; }
  .heading--white {
    color: #fff; }
  .heading--center {
    text-align: center; }
  .heading--small {
    font-weight: 400;
    font-size: 1.6rem; }
  .heading--big {
    font-size: 3.5rem; }
    @media (min-width: 768px) {
      .heading--big {
        font-size: 4.8rem; } }

.text {
  font-size: 1.4rem;
  color: #455757;
  font-family: "Roboto", sans-serif;
  line-height: 1.42857; }
  .text--white {
    color: #fff; }
  .text--center {
    text-align: center; }
  .text--big {
    font-size: 1.5rem; }
  .text--small {
    font-size: 1.1rem; }
  .text--no-wrap {
    white-space: nowrap; }
  .text--uppercase {
    text-transform: uppercase; }

.button {
  font-family: "Roboto", sans-serif;
  font-size: 1.8rem;
  color: #fff;
  padding: 15px 35px;
  background-color: #5dc6cc;
  display: table;
  margin: 10px auto 0;
  z-index: 1;
  position: relative;
  border: none;
  cursor: pointer; }
  .button:hover, .button:visited {
    color: #FFF;
    text-decoration: none; }
    .button:hover::before, .button:visited::before {
      height: 50%; }
    .button:hover::after, .button:visited::after {
      height: 50%; }
  .button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #455757;
    z-index: -1;
    transition: all .3s ease-in-out; }
  .button::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #455757;
    z-index: -1;
    transition: all .3s ease-in-out; }
  .button--bordered {
    border: 1px solid #fff; }

.box-oferta {
  display: block;
  max-width: 360px;
  height: 380px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  margin: 0 auto 40px; }
  .box-oferta a:hover {
    color: #455757; }
  @media (min-width: 768px) {
    .box-oferta {
      max-width: 345px !important; } }
  @media (min-width: 992px) {
    .box-oferta {
      max-width: 303px !important; } }
  @media (min-width: 1200px) {
    .box-oferta {
      max-width: 360px !important; } }
  @media (min-width: 992px) {
    .box-oferta--big {
      width: 614px !important;
      max-width: 614px !important; } }
  @media (min-width: 1200px) {
    .box-oferta--big {
      width: 732px !important;
      max-width: 732px !important; } }
  .box-oferta.box-oferta--active .box-oferta__title {
    padding: 20px;
    border-radius: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .box-oferta.box-oferta--active .box-oferta__title .text {
      position: static;
      top: auto;
      left: auto;
      transform: translate(0);
      text-align: left; }
    .box-oferta.box-oferta--active .box-oferta__title__plus {
      transform: rotate(45deg); }
      .box-oferta.box-oferta--active .box-oferta__title__plus:hover {
        transform: rotate(-45deg); }
    .box-oferta.box-oferta--active .box-oferta__title__list {
      display: block; }
  @media (min-width: 768px) {
    .box-oferta {
      display: inline-block;
      vertical-align: top; }
      .box-oferta:nth-child(2n+2) {
        margin-right: 0; } }
  @media (min-width: 992px) {
    .box-oferta {
      margin-right: 15px;
      margin-bottom: 15px; }
      .box-oferta:nth-child(2n+2) {
        margin-right: 15px; }
      .box-oferta:nth-child(3n+3) {
        margin-right: 0; } }
  @media (min-width: 1200px) {
    .box-oferta {
      margin-right: 30px;
      margin-bottom: 30px; }
      .box-oferta:nth-child(2n+2) {
        margin-right: 30px; }
      .box-oferta:nth-child(3n+3) {
        margin-right: 0; } }
  .box-oferta__title {
    position: absolute;
    bottom: -95px;
    left: -15px;
    width: 210px;
    height: 220px;
    background-color: #5dc6cc;
    border-radius: 50%;
    transition: all .3s ease-in-out; }
    .box-oferta__title .text {
      font-size: 1.6rem;
      font-weight: 500;
      position: absolute;
      top: 32%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .box-oferta__title__plus {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      background-color: #5dc6cc;
      border-radius: 50%;
      cursor: pointer;
      trasform-origin: 50%;
      transition: transform .3s ease-in-out; }
      .box-oferta__title__plus > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
    .box-oferta__title:hover {
      cursor: pointer; }
      .box-oferta__title:hover .box-oferta__title__plus {
        transform: rotate(135deg); }
    .box-oferta__title__list {
      display: none;
      margin-top: 15px;
      transition-delay: .5s; }
      .box-oferta__title__list li {
        position: relative;
        margin-left: 15px;
        text-align: left;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 1.2rem;
        margin-bottom: 15px; }
        .box-oferta__title__list li::before {
          content: '';
          position: absolute;
          left: -15px;
          top: 4px;
          width: 5px;
          height: 5px;
          background-color: #FFF;
          border-radius: 50%; }

.search-form {
  border-bottom: 1px solid #5dc6cc; }
  .search-form button {
    background: none;
    border: none;
    float: right; }
  .search-form input {
    border: none;
    outline: none;
    font-weight: 300;
    width: 80%;
    padding: 5px 0; }

.contact-small {
  text-align: right;
  color: #455757; }
  .contact-small p {
    font-size: 1.5rem; }
    @media (min-width: 992px) {
      .contact-small p {
        font-size: 2.1rem; } }
  .contact-small a {
    color: #5dc6cc;
    font-size: 1.8rem;
    text-decoration: none;
    font-weight: 500; }
    @media (min-width: 992px) {
      .contact-small a {
        font-size: 30px; } }

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 0%;
  height: 0%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 15;
  opacity: 0;
  transition: opacity .3s ease-in-out; }
  .overlay.js-overlay-show {
    width: 100%;
    height: 100%;
    opacity: 1; }

.swiper-container .swiper-pagination {
  bottom: 26px; }
  .swiper-container .swiper-pagination .swiper-pagination-bullet {
    background-color: #FFF;
    opacity: 1;
    width: 10px;
    height: 10px; }
    .swiper-container .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #5dc6cc; }

.bx-wrapper {
  box-shadow: none;
  border: none;
  margin: 0; }

.slider-container {
  height: 350px;
  padding-top: 0 !important;
  overflow: hidden; }
  @media (min-width: 768px) {
    .slider-container {
      height: 300px; } }
  @media (min-width: 992px) {
    .slider-container {
      height: auto; } }
  .slider-container .bx-wrapper {
    height: 350px;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .slider-container .bx-wrapper {
        height: 300px; } }
    @media (min-width: 992px) {
      .slider-container .bx-wrapper {
        height: auto; } }

.slide {
  height: 500px;
  background-repeat: no-repeat;
  background-position: right center; }
  @media (min-width: 1200px) {
    .slide {
      background-position: top center;
      height: 500px; } }

.slide__image {
  display: none;
  position: absolute;
  height: 500px; }
  @media (min-width: 768px) {
    .slide__image {
      display: block;
      position: relative; }
      .slide__image img {
        height: 100%;
        width: auto !important;
        max-width: none !important;
        position: absolute;
        top: 0;
        left: 0; } }
  @media (min-width: 992px) {
    .slide__image {
      display: block;
      position: static; } }
  .slide__image img {
    max-width: 100%; }

.slide__content {
  color: #FFF;
  font-size: 18px; }
  @media (min-width: 768px) {
    .slide__content {
      font-size: 22px;
      text-align: right; } }
  @media (min-width: 992px) {
    .slide__content {
      font-size: 38px;
      text-align: left; } }
  .slide__content h1, .slide__content h2, .slide__content h3, .slide__content h4, .slide__content h5 {
    margin: 0;
    font-weight: normal; }
  .slide__content h1 {
    font-size: 30px;
    display: block; }
    @media (min-width: 768px) {
      .slide__content h1 {
        font-size: 55px;
        line-height: 60px;
        margin-bottom: 20px; } }
  .slide__content h6 {
    font-size: 14px; }
  .slide__content em {
    color: #455757; }

.bx-pager {
  bottom: 30px !important; }

#slider-0 > div > div.bx-controls.bx-has-pager > div > div > a {
  background-color: #fff; }
  #slider-0 > div > div.bx-controls.bx-has-pager > div > div > a.active {
    background-color: #455757; }

.about {
  padding-bottom: 50px;
  font-size: 0; }
  .about__left {
    width: 100%;
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .about__left {
        width: 50%;
        display: inline-block;
        vertical-align: top; } }
    .about__left > img {
      display: block;
      margin: 0 auto 25px;
      width: 100%; }
    .about__left .text {
      display: block;
      margin: 0 auto; }
  .about__right {
    width: 100%;
    display: inline-block;
    vertical-align: top; }
    @media (min-width: 768px) {
      .about__right {
        width: 45%;
        margin-left: 5%; } }
    .about__right__doctor {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      margin-bottom: 40px;
      text-align: center; }
      .about__right__doctor:last-child {
        margin-bottom: 0; }
      .about__right__doctor__photo {
        display: inline-block;
        vertical-align: middle;
        width: 110px;
        height: 110px;
        border: 3px solid rgba(93, 198, 204, 0.23);
        border-radius: 50%;
        overflow: hidden;
        position: relative; }
        .about__right__doctor__photo > img {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .about__right__doctor__text {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        width: 80%;
        text-align: left; }
        @media (min-width: 768px) {
          .about__right__doctor__text {
            font-size: 1.6rem;
            letter-spacing: 0.075em; } }

.treatments {
  padding: 100px 0 30px;
  background-color: #9edde0;
  position: relative;
  overflow: hidden;
  text-align: center;
  z-index: 1; }
  .treatments.az::before, .treatments.az::after {
    background: none; }
  .treatments::before {
    content: '';
    background: url("../images/treatment-left.png") no-repeat center/cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 303px;
    height: 100%;
    z-index: -1;
    display: none; }
    @media (min-width: 768px) {
      .treatments::before {
        display: block; } }
  .treatments::after {
    content: '';
    background: url("../images/treatment-right.png") no-repeat center/cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 303px;
    height: 100%;
    z-index: -1;
    display: none; }
    @media (min-width: 768px) {
      .treatments::after {
        display: block; } }
  .treatments .heading {
    margin-bottom: 40px; }
  .treatments__row {
    display: block;
    width: 100%; }
    @media (min-width: 768px) {
      .treatments__row {
        margin-bottom: 20px; } }
    .treatments__row__treatment {
      display: inline-block;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      z-index: 1;
      margin-bottom: 30px;
      cursor: pointer; }
      .treatments__row__treatment__image {
        background-size: cover;
        background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .treatments__row__treatment {
          margin-bottom: 0;
          margin-right: 20px; } }
      @media (min-width: 992px) {
        .treatments__row__treatment {
          margin-right: 50px; } }
      @media (min-width: 1200px) {
        .treatments__row__treatment {
          margin-right: 80px; } }
      .treatments__row__treatment:hover::after {
        width: 100%;
        height: 100%; }
      .treatments__row__treatment:last-child {
        margin-right: 0; }
      .treatments__row__treatment::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        background-color: #dff4f5;
        border-radius: 50%;
        transition: all .3s ease-in-out; }
      .treatments__row__treatment__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8); }
      .treatments__row__treatment__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: .8; }
      .treatments__row__treatment .heading {
        font-size: 1.4rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        z-index: 1;
        color: #3c9195; }

.reviews {
  padding: 30px 0;
  font-size: 0; }
  .reviews__person {
    display: block;
    margin: 20px auto; }
    @media (min-width: 768px) {
      .reviews__person {
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        width: 360px !important; } }
    @media (min-width: 992px) {
      .reviews__person {
        width: 313px !important; } }
    @media (min-width: 1200px) {
      .reviews__person {
        width: 380px !important; } }
    .reviews__person__photo {
      width: 135px;
      height: 135px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 50%;
      border: 3px solid rgba(93, 198, 204, 0.23);
      position: relative; }
      .reviews__person__photo > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .reviews__person__review {
      padding: 19px 23px;
      position: relative; }
      .reviews__person__review::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: url("../images/quote.png") no-repeat center;
        width: 23px;
        height: 19px; }
      .reviews__person__review .text {
        line-height: 1.5; }
        .reviews__person__review .text > span {
          display: block;
          color: #5dc6cc; }
  .reviews .bx-wrapper .bx-pager.bx-default-pager a {
    background: #5dc6cc; }
  .reviews .bx-wrapper .bx-pager.bx-default-pager a.active, .reviews .bx-wrapper .bx-pager.bx-default-pager a:focus, .reviews .bx-wrapper .bx-pager.bx-default-pager a:hover {
    background: #455757; }

.todos {
  width: 100%;
  padding: 30px 0 50px;
  background-position: center;
  background-attachment: fixed; }
  @media (min-width: 768px) {
    .todos .container {
      display: flex;
      justify-content: space-between; } }
  .todos__todo {
    display: block;
    margin: 30px auto;
    width: 100%;
    text-align: center; }
    @media (min-width: 768px) {
      .todos__todo {
        width: 160px;
        position: relative; } }
    .todos__todo__icon {
      width: 160px;
      height: 160px;
      position: relative;
      border: 1px solid #fff;
      border-radius: 50%;
      margin: 0 auto; }
      .todos__todo__icon > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    @media (min-width: 768px) {
      .todos__todo .text {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%); } }

.map {
  width: 100%;
  height: 300px; }
  .map .gmnoprint {
    display: none; }

#map > div > div > div:nth-child(2) {
  display: none; }

.newsletter {
  background-color: #5dc6cc;
  padding: 50px 0; }
  .newsletter__input {
    display: block;
    margin: 50px auto 0;
    background-color: transparent;
    width: 100%;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    padding: 10px 20px;
    color: #fff;
    font-size: 1.4rem;
    font-family: "Roboto", sans-serif; }
    .newsletter__input.error {
      border-bottom: 1px solid red; }
    .newsletter__input::-webkit-input-placeholder {
      color: #fff;
      opacity: 1; }
    .newsletter__input:focus {
      outline: none;
      border-bottom: 1px solid #455757; }
    @media (min-width: 768px) {
      .newsletter__input {
        width: 360px; } }
  .newsletter .button {
    margin-top: 30px;
    outline: none; }
  .newsletter #success-msg {
    display: none;
    text-align: center;
    font-size: 28px;
    color: #FFF;
    margin-top: 40px; }
  .newsletter .error-msg {
    display: none;
    text-align: center;
    margin-top: 20px;
    color: #FFF; }

.our-clinic {
  background: url("../images/background-leaf.jpg") repeat-x center fixed;
  padding: 20px 0; }
  .our-clinic__left {
    width: 100%;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .our-clinic__left {
        display: inline-block;
        vertical-align: middle;
        width: 59%;
        margin-right: 35px; } }
    @media (min-width: 992px) {
      .our-clinic__left {
        width: 53%; } }
    .our-clinic__left .heading {
      margin-bottom: 15px; }
  .our-clinic__right {
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    position: relative; }
    @media (min-width: 768px) {
      .our-clinic__right {
        display: inline-block;
        vertical-align: middle; } }
    @media (min-width: 992px) {
      .our-clinic__right {
        width: 400px;
        height: 400px; } }
    .our-clinic__right > img {
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }

.staff {
  padding: 20px 0; }
  .staff .heading {
    margin-bottom: 60px; }
  .staff__person {
    display: block;
    width: 100%;
    margin-bottom: 100px; }
    .staff__person:last-child {
      margin-bottom: 0; }
    .staff__person__photo {
      width: 260px;
      height: 350px;
      position: relative;
      margin: 0 auto 15px; }
      @media (min-width: 768px) {
        .staff__person__photo {
          display: inline-block;
          vertical-align: top;
          margin-right: 20px; } }
      .staff__person__photo > span {
        position: absolute;
        top: -60px;
        left: -60px;
        width: 140px;
        height: 140px;
        background-color: #5dc6cc;
        border-radius: 50%;
        z-index: -1; }
        .staff__person__photo > span::after {
          content: '';
          position: absolute;
          bottom: -40px;
          left: 40%;
          transform: translateX(-50%);
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background-color: #455757;
          opacity: .9; }
      .staff__person__photo > img {
        width: 100%; }
    @media (min-width: 768px) {
      .staff__person__text {
        display: inline-block;
        vertical-align: top;
        width: 60%;
        margin-top: 25px; } }
    .staff__person__text .heading {
      margin-bottom: 15px; }
      @media (min-width: 768px) {
        .staff__person__text .heading {
          margin-bottom: 15px; } }
      .staff__person__text .heading .text {
        margin-top: 5px; }

.certifications {
  background-color: #5dc6cc;
  padding: 20px 0;
  font-size: 0;
  text-align: center; }
  .certifications .heading {
    margin-bottom: 40px; }
  .certifications__certification {
    width: 100%;
    height: 190px;
    background-color: #b0d4d6;
    margin-bottom: 15px;
    overflow: hidden;
    cursor: pointer;
    position: relative; }
    .certifications__certification::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      background-color: rgba(93, 198, 204, 0);
      transition: background .3s ease-in-out; }
    @media (min-width: 768px) {
      .certifications__certification {
        display: inline-block;
        vertical-align: top;
        margin-right: 30px;
        margin-bottom: 30px;
        width: calc(100% / 2 - 30px); }
        .certifications__certification:nth-child(2n+2) {
          margin-right: 0; } }
    @media (min-width: 992px) {
      .certifications__certification {
        width: calc(100% / 4 - 15px);
        margin-right: 15px;
        margin-bottom: 15px; }
        .certifications__certification:nth-child(2n+2) {
          margin-right: 15px; }
        .certifications__certification:nth-child(4n+4) {
          margin-right: 0; } }
    @media (min-width: 1200px) {
      .certifications__certification {
        width: calc(100% / 4 - 30px);
        margin-right: 30px;
        margin-bottom: 30px; }
        .certifications__certification:nth-child(2n+2) {
          margin-right: 30px; }
        .certifications__certification:nth-child(4n+4) {
          margin-right: 0; } }
    .certifications__certification > img {
      width: 100%; }
    .certifications__certification:hover::after {
      width: 100%;
      height: 100%;
      background-color: rgba(93, 198, 204, 0.5); }
    .certifications__certification:hover > img {
      transform: scale(1.1); }
  .certifications__popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    z-index: 20;
    transition: all .3s ease-in-out;
    height: 75vh;
    width: 90%; }
    .certifications__popup img {
      max-width: 100%;
      max-height: 85vh; }
    @media (min-width: 768px) {
      .certifications__popup {
        width: auto;
        height: auto; } }
    .certifications__popup.popup-show {
      transform: translate(-50%, -50%) scale(1); }
    .certifications__popup__close {
      position: absolute;
      top: -20px;
      right: -20px;
      width: 40px;
      height: 40px;
      background-color: #000;
      font-size: 3rem;
      font-weight: bold;
      color: #fff;
      cursor: pointer; }

.boxes {
  padding-top: 30px; }
  .boxes .box {
    display: block;
    height: 282px;
    margin-bottom: 30px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    border: 1px solid #5dc6cc; }
    @media (min-width: 768px) {
      .boxes .box {
        height: 350px; } }
    .boxes .box__title {
      height: 150px;
      width: 150px;
      text-align: center;
      background: #5dc6cc;
      color: #FFF;
      position: absolute;
      bottom: 15px;
      right: 15px;
      border-radius: 100px;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: bold; }
      .boxes .box__title__inner {
        position: relative;
        height: 150px; }
        .boxes .box__title__inner span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
          width: 150px; }

.oferta {
  padding: 50px 0;
  text-align: center; }
  @media (min-width: 768px) {
    .oferta {
      text-align: left;
      font-size: 0; } }
  .oferta .heading {
    margin-bottom: 50px; }

.box-oferta {
  display: block;
  max-width: 360px;
  height: 380px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  overflow: hidden;
  margin: 0 auto 30px; }
  .box-oferta a {
    color: #FFF;
    text-decoration: none; }
  @media (min-width: 992px) {
    .box-oferta--big {
      width: 614px !important;
      max-width: 614px !important; } }
  @media (min-width: 1200px) {
    .box-oferta--big {
      width: 732px !important;
      max-width: 732px !important; } }
  .box-oferta.box-oferta--active .box-oferta__title {
    padding: 20px;
    border-radius: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .box-oferta.box-oferta--active .box-oferta__title .text {
      position: static;
      top: auto;
      left: auto;
      transform: translate(0);
      text-align: left; }
    .box-oferta.box-oferta--active .box-oferta__title__plus {
      transform: rotate(45deg); }
      .box-oferta.box-oferta--active .box-oferta__title__plus:hover {
        transform: rotate(-45deg); }
    .box-oferta.box-oferta--active .box-oferta__title__list {
      display: block;
      max-height: 300px;
      overflow-y: scroll; }
  @media (min-width: 768px) {
    .box-oferta {
      display: inline-block;
      vertical-align: top;
      margin-right: 30px; }
      .box-oferta:nth-child(2n+2) {
        margin-right: 0; } }
  @media (min-width: 992px) {
    .box-oferta {
      margin-right: 15px;
      margin-bottom: 15px; }
      .box-oferta:nth-child(2n+2) {
        margin-right: 15px; }
      .box-oferta:nth-child(3n+3) {
        margin-right: 0; } }
  @media (min-width: 1200px) {
    .box-oferta {
      margin-right: 30px;
      margin-bottom: 30px; }
      .box-oferta:nth-child(2n+2) {
        margin-right: 30px; }
      .box-oferta:nth-child(3n+3) {
        margin-right: 0; } }
  .box-oferta__title {
    position: absolute;
    bottom: -95px;
    left: -15px;
    width: 210px;
    height: 220px;
    background-color: #5dc6cc;
    border-radius: 50%;
    transition: all .3s ease-in-out; }
    .box-oferta__title .text {
      font-size: 15px;
      font-weight: 500;
      position: absolute;
      top: 32%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .box-oferta__title__plus {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 50px;
      background-color: #5dc6cc;
      border-radius: 50%;
      cursor: pointer;
      trasform-origin: 50%;
      transition: transform .3s ease-in-out; }
      .box-oferta__title__plus > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); }
      .box-oferta__title__plus:hover {
        transform: rotate(135deg); }
    .box-oferta__title__list {
      display: none;
      margin-top: 15px; }
      .box-oferta__title__list li {
        position: relative;
        margin-left: 15px;
        text-align: left;
        color: #fff;
        font-family: "Roboto", sans-serif;
        font-size: 13px;
        line-height: 13px;
        margin-bottom: 15px; }
        .box-oferta__title__list li::before {
          content: '';
          position: absolute;
          left: -15px;
          top: 4px;
          width: 5px;
          height: 5px;
          background-color: #FFF;
          border-radius: 50%; }

.cennik {
  padding: 50px 0; }
  .cennik .cennik__inner {
    position: relative; }
  .cennik .medi {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 90px; }
    .cennik .medi img {
      max-width: 100%; }
    @media (min-width: 768px) {
      .cennik .medi {
        display: block;
        position: absolute;
        top: -20px;
        right: 0;
        width: 100px; } }
    @media (min-width: 992px) {
      .cennik .medi {
        width: auto;
        display: block;
        position: absolute;
        top: 0;
        right: 0; } }
  .cennik__list {
    margin-top: 10px;
    position: relative; }
    .cennik__list__mobileChoose {
      display: block;
      position: absolute;
      z-index: 11;
      background-color: #fff;
      width: 100%;
      height: 60px;
      border: 1px solid #5dc6cc;
      text-align: center;
      font-size: 1.6rem;
      line-height: 60px;
      font-family: "Roboto", sans-serif;
      list-style: none; }
      .cennik__list__mobileChoose.js-mobileChoose-active::after {
        transform: translateY(-50%) rotate(180deg); }
      .cennik__list__mobileChoose::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%) rotate(0);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 8px 0 8px;
        border-color: #5dc6cc transparent transparent transparent;
        transition: all .3s ease-in-out; }
      @media (min-width: 768px) {
        .cennik__list__mobileChoose {
          display: none; } }
    .cennik__list__choose {
      overflow: hidden;
      height: 60px;
      position: relative;
      z-index: 10; }
      .cennik__list__choose.js-cennik__list__choose-show {
        overflow: visible;
        top: 62px; }
      .cennik__list__choose li {
        display: block;
        padding: 15px 20px;
        background-color: #5dc6cc;
        color: #fff;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        font-family: "Roboto", sans-serif;
        font-size: 2rem;
        border: 1px solid #5dc6cc;
        cursor: pointer;
        margin: 1px 0; }
        @media (min-width: 768px) {
          .cennik__list__choose li {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            font-size: 1.5rem;
            display: inline-block;
            vertical-align: top;
            padding: 14px; } }
        @media (min-width: 992px) {
          .cennik__list__choose li {
            padding: 15px 20px; } }
        @media (min-width: 1200px) {
          .cennik__list__choose li {
            font-size: 1.5rem;
            padding: 18px 20px; } }
        .cennik__list__choose li.price-active {
          border-bottom: 1px solid #5dc6cc; }
          @media (min-width: 768px) {
            .cennik__list__choose li.price-active {
              background-color: #fff;
              color: #5dc6cc;
              border-bottom: 1px solid #fff; } }
  .cennik__price {
    border: 1px solid #5dc6cc;
    padding: 30px;
    margin-top: 10px;
    display: none; }
    @media (min-width: 768px) {
      .cennik__price {
        margin-top: -9px; } }
    @media (min-width: 992px) {
      .cennik__price {
        margin-top: -7px; } }
    @media (min-width: 1200px) {
      .cennik__price {
        margin-top: -1px; } }
    .cennik__price.cennik__price--active {
      display: block; }
    .cennik__price h5 {
      font-family: "Roboto", sans-serif;
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: 5px;
      color: #455757; }
      @media (min-width: 768px) {
        .cennik__price h5 {
          font-size: 1.5rem; } }
    .cennik__price p {
      font-family: 1.5rem;
      font-weight: 100; }
    .cennik__price .price {
      float: right; }
    .cennik__price__action {
      margin-bottom: 15px; }
      .cennik__price__action ul li {
        font-family: "Roboto", sans-serif;
        font-size: 1.3rem;
        color: #455757;
        font-weight: 100;
        position: relative;
        margin: 6px 0;
        margin-left: 10px;
        line-height: 1.3333;
        border-bottom: 1px solid transparent; }
        .cennik__price__action ul li.cross-out {
          color: red; }
          .cennik__price__action ul li.cross-out .price .cross {
            text-decoration: line-through;
            color: #455757; }
        @media (min-width: 768px) {
          .cennik__price__action ul li {
            font-size: 1.5rem;
            margin-left: 30px; } }
        .cennik__price__action ul li:hover {
          font-weight: 800;
          border-bottom: 1px solid #5dc6cc;
          cursor: pointer; }
        .cennik__price__action ul li::before {
          content: '';
          position: absolute;
          left: -8px;
          top: 9px;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: #5dc6cc; }
          @media (min-width: 768px) {
            .cennik__price__action ul li::before {
              top: 9px;
              left: -10px; } }

.module--first {
  padding-top: 50px; }

.kontakt {
  margin-top: 50px; }
  .kontakt .heading {
    margin-bottom: 30px; }
  @media (min-width: 768px) {
    .kontakt .wrapper-map {
      position: relative; } }
  .kontakt .wrapper-map__box {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 0 20px 20px; }
    @media (min-width: 768px) {
      .kontakt .wrapper-map__box {
        padding: 30px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 40px;
        z-index: 1;
        width: 400px; } }
    .kontakt .wrapper-map__box__phone, .kontakt .wrapper-map__box__mail, .kontakt .wrapper-map__box__location, .kontakt .wrapper-map__box__opening-hours {
      position: relative;
      padding: 20px 0;
      border-bottom: 1px solid #5dc6cc; }
      .kontakt .wrapper-map__box__phone::after, .kontakt .wrapper-map__box__mail::after, .kontakt .wrapper-map__box__location::after, .kontakt .wrapper-map__box__opening-hours::after {
        content: '';
        width: 20px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%); }
      @media (min-width: 1200px) {
        .kontakt .wrapper-map__box__phone, .kontakt .wrapper-map__box__mail, .kontakt .wrapper-map__box__location, .kontakt .wrapper-map__box__opening-hours {
          padding: 40px 0; }
          .kontakt .wrapper-map__box__phone::after, .kontakt .wrapper-map__box__mail::after, .kontakt .wrapper-map__box__location::after, .kontakt .wrapper-map__box__opening-hours::after {
            width: 30px; } }
    .kontakt .wrapper-map__box__phone::after {
      background: url("../images/phone-black.jpg") no-repeat center/cover;
      height: 20px; }
      @media (min-width: 1200px) {
        .kontakt .wrapper-map__box__phone::after {
          height: 30px; } }
    .kontakt .wrapper-map__box__mail::after {
      background: url("../images/mail-black.jpg") no-repeat center/cover;
      height: 15px; }
      @media (min-width: 1200px) {
        .kontakt .wrapper-map__box__mail::after {
          height: 22px; } }
    .kontakt .wrapper-map__box__location::after {
      background: url("../images/marker-black.jpg") no-repeat center/cover;
      height: 28px; }
      @media (min-width: 1200px) {
        .kontakt .wrapper-map__box__location::after {
          width: 22px;
          height: 30px; } }
    .kontakt .wrapper-map__box__opening-hours h6 {
      font-family: "Roboto", sans-serif;
      font-size: 1.6rem;
      font-weight: 800;
      color: #455757; }
    .kontakt .wrapper-map__box__opening-hours::after {
      background: url("../images/clock-black.jpg") no-repeat center/cover;
      height: 20px; }
      @media (min-width: 1200px) {
        .kontakt .wrapper-map__box__opening-hours::after {
          height: 30px; } }
  .kontakt .wrapper-map .map {
    display: block;
    width: 100%;
    height: 310px; }
    @media (min-width: 768px) {
      .kontakt .wrapper-map .map {
        height: 530px; } }
    @media (min-width: 1200px) {
      .kontakt .wrapper-map .map {
        height: 710px; } }

.treatment {
  padding-top: 50px;
  padding-bottom: 30px; }
  .treatment h1 {
    font-size: 35px;
    color: #5dc6cc;
    text-transform: lowercase;
    margin-bottom: 30px;
    font-weight: 300; }
  .treatment h2 {
    font-weight: 300;
    font-size: 20px;
    color: #5dc6cc;
    text-transform: lowercase;
    margin-bottom: 30px; }
  .treatment img {
    max-width: 100%;
    height: auto !important; }
  .treatment__content {
    font-size: 14px;
    color: #455757; }
    .treatment__content p {
      margin-bottom: 30px; }
    .treatment__content ul {
      padding-left: 20px;
      list-style: circle;
      margin-bottom: 30px; }
      .treatment__content ul li {
        margin-bottom: 15px; }
    .treatment__content em {
      color: #5dc6cc;
      font-weight: bold; }
  .treatment__footer {
    text-align: center;
    font-size: 28px; }
    .treatment__footer span {
      font-size: 41px;
      color: #5dc6cc;
      font-weight: bold; }
  .treatment__related {
    padding: 30px 0; }
    .treatment__related h2 {
      font-weight: bold;
      text-transform: none; }
  .treatment .boxes .box {
    height: 350px;
    border: 1px solid #5dc6cc; }

.az {
  text-align: center;
  padding: 50px 0; }

.error-404 {
  padding: 50px 0;
  color: #5dc6cc;
  font-size: 50px;
  text-align: center; }
